import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import NFTProperty from "../components/NFTProperty.js";
import SimpleText from "./SimpleText.js";
import './Inventory/Inventory.css'
import Collapsible from "react-collapsible";
import Modal from "react-modal";
//import bIOS from './IsIOS.js';
import iVersion from './Version.js';
import ChatIcon from "../images/chaticon.png";
import Features from "../Features.js";

const bDev = (process.env.REACT_APP_API === 'dev' || process.env.REACT_APP_API === 'staging');

function NFTViewer(pProps) 
{
    const [pNFTData, setNFTData] = useState(null);
    const [pRootData, setRootData] = useState(null);
    const [isModalOpen, setModalOpen] = useState(false);

    const navigate = useNavigate ();

    useEffect (() =>
    {
        if (!pProps.pNFT)
            return;

        // get opensea metadata to display.
        var pData = pProps.pNFT.publicMetadata;
        // mutate stuff to be like opensea metadata format
        setNFTData (pData)
        setRootData (pProps.pNFT);
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);

    if (pNFTData === null)
    {
        return <></>
    }
    else
    {
        const aAttributes = pNFTData.attributes;
        let aProperties = [];

        if (aAttributes)
        {
            for (var i = 0; i < aAttributes.length; i++)
            {
                const pAttribute = aAttributes[i];
                var sPercent = null;
                var iTotal = parseInt (pAttribute.iTotal);  // for some reason, mysql query turning the total into a string.
                if (iTotal > 0)
                {
                    sPercent = Math.round (pAttribute.iCount / iTotal * 100.0);
                    if (sPercent <= 0)
                        sPercent = "< 1";
                    sPercent += '%';
                }
                aProperties.push (                    
                    <NFTProperty key={pAttribute.trait_type} sLabel={pAttribute.trait_type} sValue={pAttribute.value} sPercent={sPercent} />
                );
            }
        }

        var sDesc = pNFTData.description;

        var bOpenByDefault = localStorage.getItem ("DescOpen");
        bOpenByDefault = (bOpenByDefault === "true" || bOpenByDefault === null);

        var pImage = null;
        var pFullImage = null;
        if (pNFTData.animation_url)
        {
            var sPoster = pNFTData.animation_url.slice (0, pNFTData.animation_url.lastIndexOf ('.')) + ".png?v=" + iVersion;

            pImage =
            <div className="NFTVideoWrapper" onClick={()=>setModalOpen(true)}>
                <video playsInline autoPlay muted loop poster={sPoster}>
                    <source src={pNFTData.animation_url + '?v=' + iVersion} type={pNFTData.animation_url_mime_type} />
                    Your browser does not support the video tag.
                </video>
                {(pProps.bAllowChat && Features.bChat)? <button className="InventoryChatButton" disabled={false} onClick={handleChat}><img className="InventoryChatButtonIcon" src={ChatIcon} alt=""></img><span className="ButtonText">HOWL</span></button> : null}
            </div>

            pFullImage = 
            <video playsInline autoPlay muted loop poster={sPoster} style={{width:"100%",height:"100%",objectFit:"contain"}}>
                <source src={pNFTData.animation_url + '?v=' + iVersion} type={pNFTData.animation_url_mime_type} />
                Your browser does not support the video tag.
            </video>
        }
        else
        {
            pImage = 
            <div className="NFTVideoWrapper" onClick={()=>setModalOpen(true)}>
                <div className='NFTImageContainer'>
                    <img className = "NFTImage" src={pNFTData.image + '?v=' + iVersion} alt={pNFTData.name}/>
                </div>
            </div>
            pFullImage = <img src={pNFTData.image + '?v=' + iVersion} alt={pNFTData.name} style={{width:"100%",height:"100%",objectFit:"contain"}}/>
        }

        function closeModal ()
        {
            setModalOpen (false);
        }

        function handleChat ()
        {
            // do chat popup
            navigate ('/chat');
            
        }
    
        var sID = null;
        if (Features.bShowBlockchainID)
        {
            if (pRootData.blockchainTokenId)
            {
                if (pProps.sContractAddress)
                {
                    let sPolyscanURL;
                    if (bDev)
                        sPolyscanURL = "https://amoy.polygonscan.com/token/" + pProps.sContractAddress + "?a=" + pRootData.blockchainTokenId;
                    else
                        sPolyscanURL = "https://polygonscan.com/token/" + pProps.sContractAddress + "?a=" + pRootData.blockchainTokenId;
                
                    sID = <span className="InventoryItemIDLabel">ID#: <span className="InventoryItemID"><a href={sPolyscanURL} target="_blank" rel="noreferrer">{pRootData.blockchainTokenId}</a></span></span>;
                }
                else
                    sID = <span className="InventoryItemIDLabel">ID#: <span className="InventoryItemID">{pRootData.blockchainTokenId}</span></span>;
            }
            else
                sID = <span className="InventoryItemIDLabel">Minting...</span>;
        }

        return (
            <div style={{display:"flex", width:"100%"}}>
                <div className="NFTViewer">
                    <p className="InventoryItemHeader"><span className="InventoryItemName">{pNFTData.name}</span>{sID}</p>
                    <div className="NFTVideoDiv">
                        {pImage}
                        <div className="NFTProperties">
                            {aProperties}
                        </div>
                    </div>
                    <Collapsible trigger="DESCRIPTION" open={ bOpenByDefault } onOpening={()=>localStorage.setItem ("DescOpen", true)} onClosing={()=>localStorage.setItem ("DescOpen", false)}>
                        <SimpleText text={sDesc} className="NFTDescription" />
                    </Collapsible>                
                </div>       
                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="{pNFTData.name}"
                    className="ModalDialog"
                    overlayClassName="ModalDialogOverlay"
                    closeTimeoutMS={600} 
                    shouldCloseOnOverlayClick={true}
                >
                    <div style={{width:"100vw", height:"100vh", display:"flex", justifyContent:"center", alignItems:"center"}} onClick={closeModal}>
                        {pFullImage}
                    </div>
                </Modal>
            </div>
        );
    }
}
  
export default NFTViewer;
  