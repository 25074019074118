import React, { createContext, useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import { doPortalRequest, doWhitelistRequest } from "../api/MemberPortal";
import {
    //GoogleAuthProvider,
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    browserSessionPersistence     
} from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";

const sQueryString = require('query-string');
let sStartPath = window.location.pathname;

const pParams = sQueryString.parse(window.location.search);
const sCode = pParams.sCode;
const sReferredBy = pParams.rb;

console.log ("env:" + process.env.REACT_APP_API);

const bDev = (process.env.REACT_APP_API === 'dev' || process.env.REACT_APP_API === 'staging');
const bDebug = ((pParams.debug === '1' || pParams.debug === 'true' ) && bDev);

//const bDev = true;// TODO:  how to set this based on build?

const LOGIN_STATES =
{
    DEFAULT : 0,
    LOGGING_IN : 1,
    LOGIN_SUCCESS : 2,
    LOGIN_FAILED_ALREADY_REGISTERED : 3,
    LOGIN_FAILED_UNKNOWN : 100,
}

// Create two context:
// AppContext: to query the context state
// AppContextUpdate: to mutate the context state
const AppContext = createContext({});
const AppContextUpdate = createContext({});

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const pFirebaseConfigDev = {
    apiKey: "AIzaSyAXceUYkTwt-hl9I5CnhS4KVAAnilHT9uA",
    authDomain: "cosmicfoundrydev.firebaseapp.com",
    projectId: "cosmicfoundrydev",
    storageBucket: "cosmicfoundrydev.appspot.com",
    messagingSenderId: "477461003390",
    appId: "1:477461003390:web:f99c7f7cd89d35ff816c2f",
    measurementId: "G-NG5B06HLEG"
};
const pFirebaseConfigStaging = 
{
    apiKey: "AIzaSyDUNK51Ugu3US4-E_y7fgUWBRsU8dKXMds",
    authDomain: "cosmicfoundrystaging.firebaseapp.com",
    projectId: "cosmicfoundrystaging",
    storageBucket: "cosmicfoundrystaging.appspot.com",
    messagingSenderId: "813457732267",
    appId: "1:813457732267:web:35e365ee41b3dd56177dee"
};
  
const pFirebaseConfigProd = {
    apiKey: "AIzaSyDOU0GI9MGi5MbzoOwXSNk9r-SBxQz9n4k",
    authDomain: "cosmic-foundry-prod.firebaseapp.com",
    projectId: "cosmic-foundry-prod",
    storageBucket: "cosmic-foundry-prod.appspot.com",
    messagingSenderId: "435809877781",
    appId: "1:435809877781:web:57a60c6953bbe2357ef31c",
    measurementId: "G-X1N55STYM0"
};

const pFirebaseConfig = process.env.REACT_APP_API === 'dev' ? pFirebaseConfigDev : (process.env.REACT_APP_API === 'staging'? pFirebaseConfigStaging : pFirebaseConfigProd);

const pFirebaseApp = initializeApp(pFirebaseConfig);
const pFirebaseAuth = getAuth(pFirebaseApp);
pFirebaseAuth.setPersistence(browserSessionPersistence)

let pFirebaseUser = null;
let g_sUserName = null;
let g_bSubscribe = null;
let g_iGuestUserID = 0;
let g_sGuestPassword = null;

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function AppProvider({ children })
{
    const pContextData =
    {
        bMaintenance: false,
        sMaintenanceMessage : null,
        bDebug: bDebug,
        pParams: pParams,
        sCode: sCode,
        sReferredBy : sReferredBy,
        sWalletAddress: "undefined",
        iBalance: 0,
        pClient: null,
        bConnecting: false,
        iErrorCode: -1,
        iMintTimestamp: 0,
        sDate: "1/1/2001 10:00AM PST",
        iLimit: 2,
        sEmail: null,
        sRegisterEmail : null,
        sUserName: null,
        iLoginState : LOGIN_STATES.DEFAULT,
        iCodeVerifyState : 0, // hack 0 = in progress, 1 = success, -1 = fail
        pUserData : null,
        pGameVars : {},
        sAgentTemplateID : null,
        onLoginWithPassword : logInWithEmailAndPassword,
        onLogout : logout,
        onRegisterWithPassword : registerWithEmailAndPassword,
        onSendEmailVerification : _sendVerificationEmail,
        updateAppData : updateAppData,
        checkVerificationStatus : checkVerificationStatus,
        sendPasswordResetEmail : _sendPasswordResetEmail,
        onCheckUserName : checkUserName,
        getFirebaseToken : getFirebaseToken,
        refreshUserData : refreshUserData,
    };

    const [appDetails, setAppDetails] = useState(pContextData);

    const navigate = useNavigate();

    /*
    useEffect(() => {
        // hack for debugging.  Please remove!!!
        updateAppData ("sEmail", "albymack@gmail.com"); // hack.  remove this later.
    }, []);
    */

    const pLocation = useLocation();
    useEffect(() => {
        async function getAuthToken (pUser)
        {
            pFirebaseUser = pUser;
            /*
            let sFirebaseUserIDToken = sessionStorage.getItem ("sFirebaseToken");
            if (!sFirebaseUserIDToken)
            {
                // User is signed in.
                sFirebaseUserIDToken = await pUser.getIdToken(true);
            }
            */
            const sFirebaseUserIDToken = await pUser.getIdToken(true);

            let pData = await doPortalRequest (sFirebaseUserIDToken, "registerUser", "sCode=" + (sCode ? encodeURIComponent(sCode) : "") + "&sReferredByCode=" + (sReferredBy ? encodeURIComponent(sReferredBy) : "") + "&sUserName=" + (g_sUserName ? encodeURIComponent (g_sUserName) : "") + (g_bSubscribe === null ? "" : ("&bSubscribe=" + (g_bSubscribe ? 1 : 0)) + "&iGuestUserID=" + (g_iGuestUserID ? encodeURIComponent (g_iGuestUserID) : "") + "&sGuestPassword=" + (g_sGuestPassword ? encodeURIComponent (g_sGuestPassword) : "")));

            // check for error response
            if (!pData) 
            {
                // get error code or no data, this is bad.  Server not able to verify this player.
                // switch to not authorized page.
                updateAppData ({"iErrorCode": 104, "iLoginState": LOGIN_STATES.LOGIN_FAILED_UNKNOWN});

                // since we logged in but servercomm failed.  Log out
                await signOut (pFirebaseAuth);

                // not logged in yet, verify code if there is one prior to showing login page
                // so we can show message about free NFT or not.
                await verifyCode ();
            }
            else {
                if (pData.iErrorCode === 0) 
                {
                    // debug
                    if (bDebug)
                    {
                        pData.pUserData.pNFT = {
                            publicMetadata :
                            {
                                Name : "Agent Vulture",
                                image : "https://s3.us-west-2.amazonaws.com/cosmicdev.asylumlabsinc.com/nft/images/NFT_0.jpg",
                                image_url : "https://s3.us-west-2.amazonaws.com/cosmicdev.asylumlabsinc.com/nft/images/NFT_0.jpg",
                                description : "***True name***: Kthabh'vhu\n\n***Classification***: Lessor Servitor Entity\n\n***Lore***: Kthabh'vhu is often depicted as a great sea creature, perhaps representing the depths of his power.  If summoned, he can take many forms but seems to prefer that of a diseased and decrepit human male when on earth. This entity is ancient beyond comprehension.  He has gone by many names and been both feared and loved by countless civilizations through the ages. In western religions and occult traditions Forneus is a ‘Great Marquis of Hell’ who has twenty-nine legions of demons under his rule. He teaches languages and rhetoric, but also manipulates minds and seeks power and control across many dimensions.\n\nKthabh'vhu is often depicted as a great sea creature, perhaps representing the depths of his power.  If summoned, he can take many forms but seems to prefer that of a diseased and decrepit human male when on earth.\n\n More project info at: https://cthulhuverse.io.",
                                animation_url : "https://s3.us-west-2.amazonaws.com/cosmicdev.asylumlabsinc.com/nft/images/NFT_0.mp4",
                                animation_url_mime_type : "video/mp4",
                                attributes: [
                                    { trait_type:'Level', 'value':1 },
                                    { trait_type:'XP', 'value' : 0 },
                                    { trait_type:'Rarity', 'value' : "Mythic" },
                                    { trait_type:'Stars', 'value' : "5" },
                                    { trait_type:'Generation', 'value' : "1" },
                                    { trait_type:'Blah1', 'value' : "blah" },
                                    { trait_type:'Blah2', 'value' : "blah" },
                                    { trait_type:'Blah3', 'value' : "blah" },
                                    { trait_type:'Blah4', 'value' : "blah" },
                                    { trait_type:'Blah5', 'value' : "blah" },
                                    { trait_type:'Blah6', 'value' : "blah" },
                                    { trait_type:'Blah7', 'value' : "blah" },
                                    { trait_type:'Blah8', 'value' : "blah" },
                                    { trait_type:'Blah9', 'value' : "blah" },
                                    { trait_type:'Blah10', 'value' : "blah" },
                                    { trait_type:'Blah11', 'value' : "blah" },
                                    { trait_type:'Blah12', 'value' : "blah" },
                                    { trait_type:'Blah13', 'value' : "blah" }
                                ]
                            }
                        }

                        pData.pUserData.iNewUserRaffleTickets = 1;
                        pData.pUserData.iReferralRaffleTickets = 1;
                        pData.pUserData.iTotalTickets = 2;
                        pData.pUserData.iRaffleTickets = 2;
                        pData.pUserData.bInWhitelist = true;

                        pData.pUserData.iLoginRewardPoints = 20;
                        pData.pUserData.iLoginRewardRaffleTickets = 0;
                        pData.pUserData.iLoginRewardDay = 2;                      
                    }

                    // create a gamevar map to make lookup easier for game vars
                    const aGameVars = pData.pUserData.aGameVars;
                    const pGameVars = {};
                    if (aGameVars)
                    {
                        for (var i = 0; i < aGameVars.length; i++)
                        {
                            var pGameVar = aGameVars[i];
                            switch (pGameVar.sType.toLowerCase())
                            {
                                case "float":
                                case "number":
                                    pGameVars[pGameVar.sVarID] = parseFloat (aGameVars[i].sValue);
                                    break;
                                case "int":
                                    pGameVars[pGameVar.sVarID] = parseInt (aGameVars[i].sValue);
                                    break;
                                case "array":
                                    pGameVars[pGameVar.sVarID] = aGameVars[i].sValue.split (',');
                                    break;
                                case "json":
                                    pGameVars[pGameVar.sVarID] = JSON.parse (aGameVars[i].sValue);
                                    break;
                                default:
                                    pGameVars[pGameVar.sVarID] = aGameVars[i].sValue;
                                    break;
                            }
                        }
                    }
                    delete (pData.pUserData.aGameVars);
                    pData.pUserData.bRaffleStarted = pData.pUserData.pRaffleDrawInfo?.bActive;

                    const sAgentTemplateID = pData.sAgentTokenTemplateID;

                    // success.  grab information out of data
                    updateAppData ({"sFirebaseUserIDToken" : sFirebaseUserIDToken, "pUserData" : pData.pUserData, "iLoginState" : LOGIN_STATES.LOGIN_SUCCESS, "pGameVars" : pGameVars, sAgentTemplateID:sAgentTemplateID});

                    // if the user's email has already been verified, or the user is in whitelist (has valid code) then go to success page rather than verify page.
                    if (pUser.emailVerified || pData.pUserData.bVerified)
                    {
                        var aRedirectToHome = ['/register', '/login', '/verify', '/forgotpassword', '/maintenance', '/'];
                        if (aRedirectToHome.includes (sStartPath))
                            sStartPath = '/home';

                        //if (sStartPath === '/' || sStartPath === '/login' || sStartPath === '/register')
                        //    sStartPath = '/home';
                        console.log ("Navigating to: " + (sStartPath + window.location.search));
                        navigate(sStartPath + window.location.search);                        
                        sStartPath = '/home';
                    }
                    else
                    {
                        // only send verification email if we haven't already sent it before
                        if (localStorage.getItem ("bSendVerification") !== "true")
                        {
                            localStorage.setItem ("bSendVerification", "true");
                            _sendVerificationEmail ();
                        }
                        navigate('/verify');
                    }
                        
                    // store token into session storage so that other pages don't have to reauthenticate
//                    sessionStorage.setItem ("sFirebaseToken" ,sFirebaseUserIDToken);
                    localStorage.setItem ("bLoggedInBefore", "true");
                }
                else
                {
                    // not logged in yet, verify code if there is one prior to showing login page
                    // so we can show message about free NFT or not.
                    updateAppData ({"iLoginState": LOGIN_STATES.LOGIN_FAILED_UNKNOWN});

                    // since we logged in but servercomm failed.  Log out
                    await signOut (pFirebaseAuth);

                    await verifyCode ();
                }
            }
        }

        // setup auth state change.
        pFirebaseAuth.onAuthStateChanged(async function (pUser) 
        {
            console.log ("on auth state change", pUser);
            if (pUser) 
            {
                getAuthToken(pUser).catch(console.error);
            }
            else 
            {
                // No user is signed in.
                if (appDetails.iLoginState === LOGIN_STATES.LOGIN_SUCCESS)
                {
                    // already logged in and now not logged in.  go to login page
                    navigate('/login');
                }
                else
                {
                    await verifyCode ();
                }
            }
        });

        // validate code with server.  We should get a response back from server that includes the email
        // address of the user if it's a valid code.  TBD:  We might be able to return whether they've already been granted
        // an NFT, but that might leak private info without user name/password so we may delay that info
        // until after login.
        async function verifyCode ()
        {
            var aRedirectToLogin = ['/register', '/login'];
//            aRedirectToLogin.push ('/cursebreaker');

            var aGuestLoginAllowed = ['/cursebreaker'];
            if (!sCode)
            {
                updateAppData("iCodeVerifyState", -1);
                if (!aRedirectToLogin.includes (pLocation.pathname))
                {
                    // see if it's a page that allows guest logins. If so, check if logged in before.
                    // if not logged in before, allow the page to show, so it'll be in guest mode.
                    // if we have logged in before, then go to login page as normal.
                    if (!(aGuestLoginAllowed.includes (pLocation.pathname)) || localStorage.getItem ("bLoggedInBefore") === "true")
                    {
                        navigate('/login');
                    }
                }
                return;
            }

            var pAppData = {};
    
            // send to server the address to associate with the ID.
            let pData = await doWhitelistRequest ('verifyCode', "sCode=" + encodeURIComponent(sCode));
    
            // check for error response
            if (!pData) {
                // get error code or no data, bail
                pAppData.iCodeVerifyState = -1;
                updateAppData(pAppData);
                if (!aRedirectToLogin.includes (pLocation.pathname))
                    navigate('/login');
            }
            else {
                if (pData.iErrorCode === 0) 
                {
                    // code verified.
                    pAppData.sEmail = pData.sEmail;
                    pAppData.iCodeVerifyState = 1;
                    updateAppData(pAppData);
                    if (localStorage.getItem ("bLoggedInBefore") === "true")
                        navigate ('/login');
                    else
                        navigate('/register');
                }
                else
                    navigate ('/login');
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);

    function updateAppData(pProperty, pValue)
    {
        // first check if we're setting a null prop, if so bail.
        if (!pProperty)
            return;

        // call the functional version of setAppDetails (meaning passing it a function instead of an object)
        // because this will allow us to update the object of the context based on the previous state.
        // if we just passed in object, we may get lost updates because react batches state updates.  The functional
        // version keeps track of the state updates regardless of batching os you can update multiple times without
        // losing changes.
        setAppDetails (function (pPrevState, pCurrentProps)
        {
            // make a copy of the state, so that we will be setting a new object.  This is necessary
            // so that react hooks will trigger when the object changes.  Otherwise, if you reuse
            // the same object and just update properties, hooks will not trigger because the object hasn't
            // changed event though properties within the object changed.
            let p2 = Object.assign({}, pPrevState);
            if (typeof pProperty === 'object')
            {
                Object.assign(p2, pProperty); // copy new values into the data object
            }
            else
            {
                p2[pProperty] = pValue;    // single property, just set it.
            }
            return p2;
        });
    }

    async function refreshUserData ()
    {
        if (!pFirebaseUser)
            return;

        const sFirebaseUserIDToken = await pFirebaseUser.getIdToken();
        let pData = await doPortalRequest (sFirebaseUserIDToken, "getUserData");

        // check for error response
        if (!pData || pData.iErrorCode !== 0)  
        {
            // get error code or no data, oh well, bail
            await logout ();
        }
        else 
        {
            // success.  grab information out of data
            updateAppData ({"pUserData" : pData.pUserData});
        }
    }

    async function logout ()
    {
        try {
            await signOut(pFirebaseAuth);
            updateAppData ({"iLoginState": LOGIN_STATES.DEFAULT});
        } catch (err) {
        }
    }

    async function logInWithEmailAndPassword (sEmail, sPassword) 
    {
        g_bSubscribe = null;
        updateAppData ("iLoginState", LOGIN_STATES.LOGGING_IN);
        try {
            await signInWithEmailAndPassword(pFirebaseAuth, sEmail, sPassword);
        } catch (err) {
            updateAppData ("iLoginState", LOGIN_STATES.LOGIN_FAILED_UNKNOWN);
        }
    }

    async function registerWithEmailAndPassword (sEmail, sPassword, sUserName, bSubscribe, iGuestUserID, sGuestPassword) 
    {
        g_sUserName = sUserName;
        g_bSubscribe = bSubscribe;
        g_iGuestUserID = iGuestUserID;
        g_sGuestPassword = sGuestPassword;
        localStorage.setItem ("bSendVerification", "false");    // if we're registering, clear the flag
        updateAppData ("iLoginState", LOGIN_STATES.LOGGING_IN);
        try {
            await createUserWithEmailAndPassword(pFirebaseAuth, sEmail, sPassword);
            // we don't need to switch pages here, there's a listener on the firebaseAuth object setup in useEffect
            // that'll handle user login to get JWT token and also switch pages.

            // we'll need that JWT token to call rest API on our server.
        } catch (e) {
            console.error(e);
            const sErrorCode = e.code;
            if (sErrorCode === "auth/email-already-in-use")
            {
                // already registered.  Try just logging in instead.
                try {
                    await signInWithEmailAndPassword(pFirebaseAuth, sEmail, sPassword);
                } catch (err) {
                    updateAppData ("iLoginState", LOGIN_STATES.LOGIN_FAILED_ALREADY_REGISTERED);
                }
            }
            else
            {
                updateAppData ("iLoginState", LOGIN_STATES.LOGIN_FAILED_UNKNOWN);
            }
        }
    };

    async function _sendVerificationEmail ()
    {
        try
        {
//            const res = await sendEmailVerification(pFirebaseAuth.currentUser)
            // send to server the address to associate with the ID.
            const sFirebaseUserIDToken = await getFirebaseToken ();
            await doPortalRequest (sFirebaseUserIDToken, 'sendVerificationEmail'); 
        } catch (e)
        {
            console.error(e);
            /*
            const sErrorCode = e.code;
            if (sErrorCode === "auth/email-already-in-use")
            {
                // already registered.  Try just logging in instead.
                try {
                    await signInWithEmailAndPassword(pFirebaseAuth, sEmail, sPassword);
                } catch (err) {
                    updateAppData ("iLoginState", LoginState.LOGIN_FAILED_ALREADY_REGISTERED);
                }
            }
            else
            {
                updateAppData ("iLoginState", LoginState.LOGIN_FAILED_UNKNOWN);
            }
            */
        }
    }

    async function checkVerificationStatus ()
    {
        if (pFirebaseAuth.currentUser)
        {
            await pFirebaseAuth.currentUser.reload();
            if (pFirebaseAuth.currentUser.emailVerified) 
            {
                // notify server that we are verified now.
                try
                {
                    const sFirebaseUserIDToken = await getFirebaseToken ();
                    await doPortalRequest (sFirebaseUserIDToken, "userVerified");
                }
                catch (err)
                {
                }
                navigate('/home');                        
            }
        }
        else
        {
            // no current user, go back to home page.
            navigate('/login');                        
        }
    }

    async function _sendPasswordResetEmail (sEmail)
    {
        try
        {
//            const res = await sendPasswordResetEmail(pFirebaseAuth, sEmail);
            // send to server the address to associate with the ID.
            //const sFirebaseUserIDToken = await getFirebaseToken ();
            await doPortalRequest (null, 'sendPasswordResetEmail', "sEmail=" + encodeURIComponent (sEmail));
        } catch (e)
        {
            console.error(e);
        }
    }

    // this function calls server to check if username is unique and passes bad word filter.
    async function checkUserName (sUserName)
    {
        // send to server the address to associate with the ID.
        let pData = await doPortalRequest (null, 'checkUserName', "sUserName=" + encodeURIComponent(sUserName));
        // check for error response
        if (!pData) 
        {
            return false;
        }
        else 
        {
            if (pData.iErrorCode === 0) 
            {
                return true;
            }
            else
            {
                // username not valid, but there might be a suggestion in the response.
                if (pData.sSuggestedUserName)
                    return pData.sSuggestedUserName;
                else
                    return false;
            }
        }
    }

    async function getFirebaseToken ()
    {
        if (pFirebaseUser)
            return await pFirebaseUser.getIdToken(true);
        return null;
    }

    return (
        <AppContext.Provider value={appDetails}>
            <AppContextUpdate.Provider value={setAppDetails}>
                {children}
            </AppContextUpdate.Provider>
        </AppContext.Provider>
    );
}



export { AppProvider, AppContext, AppContextUpdate, LOGIN_STATES, bDev};
