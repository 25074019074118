function NFTProperty(pProps) 
{
    const sLabel = pProps.sLabel;
    const sValue = pProps.sValue;
    const sPercent = pProps.sPercent;

    return (
        <div key={"prop_" + sLabel} className="NFTProperty">
            <div className="NFTPropertyLabel">{sLabel}</div>
            <div className="NFTPropertyValue">{sValue} {sPercent !== null ? <span className="NFTPropertyPercent">{sPercent}</span> : null}</div>
        </div>
    );
}
  
export default NFTProperty;
  