import React, { useState, useEffect, createContext } from 'react'
import { polygon, polygonMumbai } from 'wagmi/chains'
import { useWeb3Modal } from '@web3modal/react'
import { useAccount, useDisconnect, useSwitchNetwork, useNetwork } from "wagmi";
import { AppContext } from './AppContext.js';
import { doPortalRequest } from "../api/MemberPortal";
import Features from "../Features.js";

const CONNECT_WALLET = 'CONNECT WALLET'
const bDev = (process.env.REACT_APP_API === 'dev' || process.env.REACT_APP_API === 'staging');


// Create two context:
// InventoryContext: to query the context state
// InventoryContextUpdate: to mutate the context state
const WalletContext = createContext({});
const WalletContextUpdate = createContext({});

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function WalletProvider({ children })
{
    const pContextData =
    {
        sButtonText : CONNECT_WALLET,
        pAccount : null,
        bEthereumEnabled : false,
        bConnected : false,
        pConnector : null,

        switchToPolygonNetwork : switchToPolygonNetwork,
        onConnectButton : onConnectButton,
        disconnectWallet : disconnectWallet,
    };

    const [walletDetails, setWalletDetails] = useState(pContextData);
    const { open } = useWeb3Modal();
    const { address, connector, isConnected } = useAccount();
    const { disconnect } = useDisconnect();
    const { chain } = useNetwork()
//    const { chains, error, isLoading, pendingChainId, switchNetwork } = useSwitchNetwork({throwForSwitchChainNotSupported: true});    
    const { switchNetwork } = useSwitchNetwork({throwForSwitchChainNotSupported: true});    

    const pAppData = React.useContext(AppContext);
    

    const pOnboarding = React.useRef ()
    //const [sFlashMessage, setFlashMsg] = useState ('');

    useEffect (() => 
    {
        if (!Features.bWallet)
            return;
    
        // see if we need to disconnect wallet, since we may have switched user accounts
        var sWalletEmail = localStorage.getItem ("sWalletEmail");
        if (!sWalletEmail)
        {
            // no user account?  Disconnect
            disconnectWallet ();
        }
        else
        {
            if (sWalletEmail !== pAppData.sEmail)
            {
                disconnectWallet ();
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);
    
        // create an instance fo MetamaskOnboarding class when component mounts for the first time
    useEffect(() => {
        if (!Features.bWallet)
            return;
    
        async function sendWalletAddressToServer (sWalletAddress, sChainName, iChainID)
        {
            const sFirebaseUserIDToken = await pAppData.getFirebaseToken ();
            if (sFirebaseUserIDToken)
            {
                try
                {
                    console.log ("sending wallet address to server " + sWalletAddress + ", " + sChainName + "(" + iChainID + ")");

                    await doPortalRequest (sFirebaseUserIDToken, 'setWalletAddress', {sWalletAddress:sWalletAddress, sChain:sChainName, iChainID:iChainID}); 
                } catch (e)
                {
                }
            }
        }

        if (isConnected)
        {
            updateWalletData ({"sButtonText": "CONNECTED", pAccount : address});
            localStorage.setItem ("sWalletEmail", pAppData.sEmail);            
            sendWalletAddressToServer (address, chain.name, chain.id);   
        }
        else
        {
            updateWalletData ({"sButtonText": CONNECT_WALLET, pAccount:null});
            localStorage.setItem ("sWalletEmail", null);            
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [isConnected, connector, address, chain])

    useEffect(() => {
        if (!Features.bWallet)
            return;
    
        async function checkNetwork ()
        {
            if (!chain)
                return;
            const pParams = bDev ? polygonMumbai : polygon;

            console.log ("Connected chain id " + chain.id);
            console.log ("Desired chain id " + pParams.id);

            if (chain.chainId !== pParams.id)
            {
                try {
                    await window.ethereum.request({
                        id: 1,
                        jsonrpc: "2.0",
                        method: "wallet_addEthereumChain",
                        params: [
                            bDev ? {
                                chainId: "0x13881",
                                rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                                chainName: "Mumbai",
                                nativeCurrency: {
                                    name: "MATIC",
                                    symbol: "MATIC", // 2-6 characters long
                                    decimals: 18,
                                },
                                blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
                            } : polygon,
                        ],
                    });
                } catch (addError) {
                }
                try {
                    await window.ethereum?.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: "0x" + pParams.id.toString(16) }]
                    });
                } catch (switchError) {
                    /*
                    let iErrorCode = switchError.data?.originalError?.code;
                    if (!iErrorCode)
                        iErrorCode = switchError.code;
                    if (iErrorCode === 4902) {

                        try {
                            await window.ethereum.request({
                                id: 1,
                                jsonrpc: "2.0",
                                method: "wallet_addEthereumChain",
                                params: [
                                    {
                                        chainId: "0x13881",
                                        rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                                        chainName: "Polygon Testnet Mumbai",
                                        nativeCurrency: {
                                            name: "tMATIC",
                                            symbol: "tMATIC", // 2-6 characters long
                                            decimals: 18,
                                        },
                                        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
                                    },
                                ],
                            });
                        } catch (addError) {
                        }
                    }
                    */
                    // handle other "switch" errors
                    console.error(switchError);
                }
            }
        }

        checkNetwork ();
    }, [chain, switchNetwork, Features.bWallet])

    /*
    const handleAccounts = (accounts) => {
        if (accounts.length === 0) {
          // MetaMask is locked or the user has not connected any accounts
          console.log('Please connect to MetaMask.')
        } else if (accounts[0] !== account) {
          setCurrentAccount(accounts[0])
          setAccounts(accounts)
          console.debug(web3)
          console.debug(account)
          console.debug(accounts)
        }
     }
     */

    // connect initialize onboarding or connect wallet
    async function onConnectButton ()
    {
        /*
        try {
            await window.ethereum.request({
                id: 1,
                jsonrpc: "2.0",
                method: "wallet_addEthereumChain",
                params: [
                    {
                        chainId: "0x13881",
                        rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                        chainName: "Mumbai",
                        nativeCurrency: {
                            name: "MATIC",
                            symbol: "MATIC", // 2-6 characters long
                            decimals: 18,
                        },
                        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
                    },
                ],
            });
        } catch (addError) {
        }
        try {
            await window.ethereum?.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x" + polygon.id.toString(16) }]
            });
        } catch (switchError) {
            let iErrorCode = switchError.data?.originalError?.code;
            if (!iErrorCode)
                iErrorCode = switchError.code;
            if (iErrorCode === 4902) {

                try {
                    await window.ethereum.request({
                        id: 1,
                        jsonrpc: "2.0",
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainId: "0x13881",
                                rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                                chainName: "Polygon Testnet Mumbai",
                                nativeCurrency: {
                                    name: "tMATIC",
                                    symbol: "tMATIC", // 2-6 characters long
                                    decimals: 18,
                                },
                                blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
                            },
                        ],
                    });
                } catch (addError) {
                }
            }

            // handle other "switch" errors
            console.error(switchError);
        }
            */

        //const pParams = bDev ? polygonMumbai : polygon

//        console.log ("switch chain", pParams);
//        setDefaultChain (pParams);
        try {
            await open ();
        } catch (err)
        {
            console.error (err);
        }
    }

    async function switchToPolygonNetwork ()
    {
    }

    async function disconnectWallet ()
    {
        disconnect ();

        // also clear out some properties
        updateWalletData ({sButtonText:CONNECT_WALLET, pAccount:null});
        localStorage.setItem ("sWalletEmail", null);            
    }

    function updateWalletData(pProperty, pValue)
    {
        // first check if we're setting a null prop, if so bail.
        if (!pProperty)
            return;

        // call the functional version of setAppDetails (meaning passing it a function instead of an object)
        // because this will allow us to update the object of the context based on the previous state.
        // if we just passed in object, we may get lost updates because react batches state updates.  The functional
        // version keeps track of the state updates regardless of batching os you can update multiple times without
        // losing changes.
        setWalletDetails (function (pPrevState, pCurrentProps)
        {
            // make a copy of the state, so that we will be setting a new object.  This is necessary
            // so that react hooks will trigger when the object changes.  Otherwise, if you reuse
            // the same object and just update properties, hooks will not trigger because the object hasn't
            // changed event though properties within the object changed.
            let p2 = Object.assign({}, pPrevState);
            if (typeof pProperty === 'object')
            {
                Object.assign(p2, pProperty); // copy new values into the data object
            }
            else
            {
                p2[pProperty] = pValue;    // single property, just set it.
            }
            return p2;
        });
    }

    return (
        <WalletContext.Provider value={walletDetails}>
            <WalletContextUpdate.Provider value={setWalletDetails}>
                {children}
            </WalletContextUpdate.Provider>
        </WalletContext.Provider>
    );
}

export { WalletProvider, WalletContext, WalletContextUpdate };