import React from 'react';
import NFTViewer from '../NFTViewer.js';

function ArtNFTPopup(pUserData) 
{
    let pRet = null;
    if (pUserData.pArtNFT)
    {
        pRet = <div id="NFTPopup" className="dialog modalBack" style={{minWidth:"60vw"}}>
            <div className="dialogDiv">
                <div className="NFTFreeContainer">
                    <p className="NFTFreeHeader">You received a free CthulhuVerse Digital Collectible: Mythopedia for registering!</p>
                    <NFTViewer pNFT={pUserData.pArtNFT} />
                </div>                    
            </div>                    
            <div className="dialogFrame"></div>
        </div>
        pUserData.pArtNFT = null;   // null out so we don't display again.
    }
    return pRet;
}

export default ArtNFTPopup;
