let Features;

const bDev = (process.env.REACT_APP_API === 'dev' || process.env.REACT_APP_API === 'staging');
bDev ?
Features =  // dev/staging features
{
    bWallet : false,
    bChat : true,
    bRewardStore : true,
    bShowBlockchainID : false
}
:
Features =  // prod features
{
    bWallet : false,
    bChat : true,
    bRewardStore : true,
    bShowBlockchainID : false
}

export default Features;