import React from 'react';
import { Link } from "react-router-dom";

function RegistrationRaffleTickets(pUserData) 
{
    var iRaffleTickets =  pUserData.iNewUserRaffleTickets + pUserData.iReferralRaffleTickets;   // don't count login rewards, it has it's own popup

    let pRet = null;
    if (iRaffleTickets > 0)
    {
        let aRaffleTickets = null;
        aRaffleTickets = [
            <div className="headerText" key="Raf0">
                <p>You received {iRaffleTickets > 1 ? "" : "a "}<b>Raffle Ticket{iRaffleTickets > 1 ? "s" : ""}</b> for the <b>Weekly Raffle!</b></p>
            </div>
        ];

        if (pUserData.iNewUserRaffleTickets > 0)
        {
            aRaffleTickets.push (
                <p className="dialogText" key="Raf1">For registering your account, you have received a <b>Raffle Ticket</b> for our <b>Weekly Raffle</b>.
                </p>
            );
        }
        if (pUserData.iReferralRaffleTickets > 0)
        {
            // was referred, gets 2 raffle tickets.
            aRaffleTickets.push (
                <p className="dialogText" key="Raf2">You referral code was accepted!  You have received a <b>Raffle Ticket</b> and <span className="yellow" style={{fontWeight:"bold"}}>{pUserData.pReferredByUserName}</span> has received one as well!
                </p>);
        }

        aRaffleTickets.push (
            <p className="dialogText" key="Raf3">Check the <Link to="/raffle">Weekly Raffle</Link> page often to see if you've won!</p>
        );

        pRet = <div className="dialog modalBack">
            <div className="dialogDiv">
                <div className="raffleImage" style={{margin:"auto"}}></div>
                <div className="raffleText">{aRaffleTickets}</div>
            </div>                    
            <div className="dialogFrame"></div>
        </div>

        pUserData.iNewUserRaffleTickets = 0;    // zero out so we don't display again.
        pUserData.iReferralRaffleTickets = 0;   // zero out so we don't display again.
    }
    return pRet;
}

export default RegistrationRaffleTickets;
