import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import Navbar from './Navbar.js';

function LoadingComponent(pProps) {
    const sMessage = pProps.sMessage;
    const bNav = (!pProps.nav || pProps.nav==='true');
    
    return (!pProps.bSubPage) ? 
    (
        <div className="flexContainer">
            {bNav ? <Navbar /> : null }
            <Header />
            <div className="contentDiv">
                <div className="dialogWrapperWideCentered">
                    <div className="dialog">
                        <div className="dialogDiv">
                            <img style={{display:"block",margin:"auto",height:"128px"}} src={require ("../images/cthulhu.gif")} alt="" />
                            {sMessage ? <p className="dialogTextCentered">{sMessage}</p> : null}
                        </div>
                        <div className="dialogFrame"></div>
                    </div>
                </div>
            </div>
            <Footer skipmaintenance={true} skippopups={true}/>
        </div>
    ) :
    (
        <div className="contentDiv">
            <div className="dialogWrapperWideCentered">
                <div className="dialog">
                    <div className="dialogDiv">
                        <img style={{display:"block",margin:"auto",height:"128px"}} src={require ("../images/cthulhu.gif")} alt="" />
                        {sMessage ? <p className="dialogTextCentered">{sMessage}</p> : null}
                    </div>
                    <div className="dialogFrame"></div>
                </div>
            </div>
        </div>
    );
}
export default LoadingComponent;
