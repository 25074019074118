import React from 'react';
import CheckMaintenance from './CheckMaintenance';
import ModalPopups from './Popups/ModalPopups.js';
import { getVersion } from '../api/MemberPortal.js';

const pDiscord = require("../images/CF_discord_white.png");
const pTwitter = require("../images/CF_X_white.png");
const pInstagram = require("../images/CF_insta_white.png");
const pFacebook = require("../images/CF_facebook_white.png");


function Footer(pParams) 
{
    var pMaintenance = null;
    if (!pParams.skipmaintenance)
    {
        pMaintenance = <CheckMaintenance />
    }
    return (
        <>
            <div className="footer">
                <div className="footerText">
                    <span><a href="https://discord.gg/qtbnYq3pem" target="_blank" rel="noreferrer" alt="discord"><img src={pDiscord} className="footerSocial"></img></a></span>
                    <span><a href="https://twitter.com/cthulhu_awakens" target="_blank" rel="noreferrer" alt="X"><img src={pTwitter} className="footerSocial"></img></a></span>
                    <span><a href="https://www.facebook.com/groups/1619779641736806/?ref=share" target="_blank" rel="noreferrer" alt="facebook"><img src={pFacebook} className="footerSocial"></img></a></span>
                    <span><a href="https://www.instagram.com/cthulhuawakens.io/" target="_blank" rel="noreferrer"><img src={pInstagram} className="footerSocial" alt="instagram"></img></a></span>
                </div>
                <p className="footerText"><a href="https://cosmicfoundrywiki.atlassian.net/wiki/spaces/CthulhuVerse/pages/98387/Privacy+Policy">Privacy Policy</a> | <a href="https://cosmicfoundrywiki.atlassian.net/wiki/spaces/CthulhuVerse/pages/1835032/Terms+of+Use">Terms of Service</a></p>
                <p className="footerText">&copy; 2024 <a href="https://www.cosmicfoundry.io">Cosmic Foundry Inc</a>
                    <span className="footerVersion">Rev {process.env.REACT_APP_VERSION}</span>
                </p>
                
                {pMaintenance}
            </div>
            {!pParams.skippopups && <ModalPopups />}
        </>
    );
}
  
export default Footer;
  