import { useState } from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'
import '../css/Navbar.css'
import React from 'react';
import { AppContext } from '../contexts/AppContext.js';
import {WalletContext} from '../contexts/WalletContext.js';
import Features from "../Features.js";

import pSmallLogoImage from "../images/cthulhuVerse_BnW_SM.png";

const Navbar = (pProps) => {
    const pAppData = React.useContext(AppContext);
    const pWalletData = React.useContext(WalletContext);

    const [showNavbar, setShowNavbar] = useState(false)

    const [pSearchParams] = useSearchParams ();

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    let pSmallLogo = null;
    if (!pProps.bSkipSmallLogo)
    {
        pSmallLogo = <span><a href="https://www.cthulhuverse.io" target="_blank" rel="noreferrer">
            <img className="HeaderSmall" src={pSmallLogoImage} alt="CthulhuVerse" />
        </a></span>        
    }
    
    let pConnectButton = null;
    if (Features.bWallet)
    {
        if (pWalletData.pAccount != null)
        {
            // display a cropped wallet address.
            var sWallet = pWalletData.pAccount.slice (0, 5) + "..." + pWalletData.pAccount.slice (-4);
            pConnectButton = <button className="ButtonBackground NavConnectButton" onClick={pWalletData.onConnectButton}><span className="ButtonText">{pWalletData.sButtonText} ({sWallet})</span></button>
        }
        else
        {
            pConnectButton = <button className="ButtonBackground NavConnectButton" onClick={pWalletData.onConnectButton}><span className="ButtonText">{pWalletData.sButtonText}</span></button>
        }
    }

    return (
        <nav className="navbar">
            {pSmallLogo}
            {pConnectButton}
            <div className="container">
                <div className="menu-icon" onClick={handleShowNavbar}>
                    <div className="Hamburger" />
                </div>
                <div className={`nav-elements  ${showNavbar && 'active'}`}>
                    <ul>
                        <li>
                            <NavLink to="/home">Home</NavLink>
                        </li>
                        <li>
                            <NavLink to={"/inventory/collectibles?sCollectionID=" + pAppData.sAgentTemplateID} className={({isActive}) => (isActive && pSearchParams.get ("sCollectionID") === pAppData.sAgentTemplateID) ? 'active' : ''}>Agents</NavLink>
                        </li>
                        <li>
                            <NavLink to="/inventory">Inventory</NavLink>
                        </li>
                        <li>
                            <NavLink to="/games">Games</NavLink>
                        </li>
                        <li>
                            <NavLink to="/raffle">Raffle</NavLink>
                        </li>
                        <li>
                            <NavLink to="/rewardstore">Reward Store</NavLink>
                        </li>
                        <li>
                            <NavLink to="/account">Account</NavLink>
                        </li>
                        <li>
                            <NavLink to="/login" onClick={pAppData.onLogout}>Log Out</NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;