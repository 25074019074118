import React from 'react';

function ReferralNoGood(pUserData) 
{
    let pRet = null;
    if (pUserData.bReferralCodeUsed)
    {
        pRet = <div className="dialog modalBack" style={{minWidth: "30vw"}}>
            <div className="dialogDiv">
                <div className="dialogTextCentered">Sorry, you have already used a referral code previously.  Only one use of referral code is accepted.</div>
            </div>                    
            <div className="dialogFrame"></div>
        </div>

        pUserData.bReferralCodeUsed = false;
    }
    else if (pUserData.bInvalidReferralCode)
    {
        pRet = <div className="dialog modalBack" style={{minWidth: "30vw"}}>
            <div className="dialogDiv">
                <div className="dialogTextCentered">Invalid referral code used.</div>
            </div>                    
            <div className="dialogFrame"></div>
        </div>

        pUserData.bInvalidReferralCode = false;
    }
    return pRet;
}

export default ReferralNoGood;
