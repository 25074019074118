import React, {useState, useEffect, useCallback} from 'react';

const pCALogo = require ("../images/horizontalLogo.png");

function Header(pProps) 
{
    const bDev = (process.env.REACT_APP_API === 'dev' || process.env.REACT_APP_API === 'staging');
    const [bEnableDevBanner, setDevBannerEnabled] = useState (true);

    const handleKeyDown = useCallback ((e)=>
    {
        if (e.keyCode == 112) // F1
        {
            setDevBannerEnabled (!bEnableDevBanner);
        }
    }, [bEnableDevBanner]);

    useEffect (()=>
    {
        document.addEventListener("keydown", handleKeyDown);
        return ()=>{
            document.removeEventListener('keydown',handleKeyDown,true)
        }
    }, [handleKeyDown]);

    let pWarning = null;
    if (bDev && bEnableDevBanner)
    {
        pWarning = <div className="redalert" style={{fontWeight:"bold", background:"#000000", width:"fit-content", margin:"0 auto", padding:".5em"}}>This is a development version of the website.  If you have accessed this site by accident, please go to <a href="https://member.cosmicfoundry.io">https://member.cosmicfoundry.io</a> instead.</div>
    }

    let pLogo = pCALogo;

    if (pProps.logo)    // override default logo.
        pLogo = pProps.logo;

    else if (!pProps.bUseLogo)
    {
        pLogo = null;
    }

    let pImage;
    if (pLogo)
    {
        if (pProps.nolink)
        {
            pImage = <img className="header" src={pLogo} alt="CthulhuVerse" />
        }
        else
        {
            pImage = <a href="https://www.cthulhuverse.io" target="_blank" rel="noreferrer">
                <img className="header" src={pLogo} alt="CthulhuVerse" />
            </a>        
        }
    }

    return (
        <div style={{width:"100%", textAlign:"center"}}>
            <div style={{display:"grid", justifyContent:"center"}}>
                {pImage}
            </div>
            {pWarning}
        </div>        
    );
}
  
export default Header;
  