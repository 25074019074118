import React, { useState, useEffect } from 'react';
import Modal from "react-modal";

function YesNoPopup(props) {
    const [bOpen, setOpen] = useState (props.bOpen);

    useEffect (() =>
    {
        setOpen (props.bOpen);
    }, [props.bOpen]);

    function closeModal (bOkay)
    {
        setOpen (false);
        if (props.onClose)
            props.onClose (bOkay);  // let parent component know which button was clicked.
    }

    return (
        <Modal
            isOpen={bOpen}
//            onRequestClose={closeModal}
            contentLabel="Confirmation Dialog"
            className="ModalDialog"
            overlayClassName="ModalDialogOverlay"
            closeTimeoutMS={600} 
            shouldCloseOnOverlayClick={false}
//            onAfterClose={nextModal}
        >
            <div className="dialogWrapperWideCentered">
                <div className="dialog">
                    <div className="dialogTextBox" style={{padding:"2em"}}>
                        <div style={{display:"flex", gap:"2em", alignItems:"center"}}>                    
                            {props.sImgSrc ? <img src={props.sImgSrc} style={{border:0, maxHeight:"15em"}} alt="" /> : null}
                            <div style={{display:"flex", flexDirection:"column", gap:"1em"}}>                    
                                <div className="headerText" style={{width:"unset", whiteSpace:"nowrap"}}>{props.pTitle}</div>
                                <div className="dialogTextCentered">
                                    {props.pMessage}
                                </div>
                                <div style={{display:"flex", justifyContent:"center", gap:"2em", flexWrap:"wrap"}}>
                                    <button className="BackButton" onClick={() => closeModal (true)}>
                                        <span className="ButtonText ModalYes">{props.sYes}</span>
                                    </button>         
                                    {props.sNo ?        
                                        <button className="BackButton BackButtonRed" onClick={() => closeModal (false)}>
                                            <span className="ButtonText ModalNo">{props.sNo}</span>
                                        </button>                
                                        :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default YesNoPopup;
