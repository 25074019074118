import React from 'react';

function DailyReward(pUserData, pGameVars) 
{
    const iLoginRewardPoints = pUserData.iLoginRewardPoints;
    const iLoginRewardRaffleTickets = pUserData.iLoginRewardRaffleTickets;
    const iRewardDay = pUserData.iLoginRewardDay - 1;
    if (!iLoginRewardPoints && !iLoginRewardRaffleTickets)  // no daily reward yet.
        return null;

    //var iRaffleTickets =  pUserData.iNewUserRaffleTickets + pUserData.iReferralRaffleTickets;   // don't count login rewards, it has it's own popup

    var aDailyRewards = pGameVars.aDailyRewards;

    let pRet = null;
    var aRewardDays = [];
    for (var i = 0; i < aDailyRewards.length; i++)
    {
        var pReward = aDailyRewards[i];

        var pPoints = null;
        if (pReward.iPoints > 0)
        {
            if (i !== iRewardDay || pReward.iPoints === iLoginRewardPoints)
                pPoints = <div className="DailyRewardItem"><div className="DailyPointsImage"></div><div className="DialyPointsAmount">{pReward.iPoints}<br />Reward Points</div></div>
            else
                pPoints = <div className="DailyRewardItem"><div className="DailyPointsImage"></div><div className="DialyPointsAmount"><span style={{textDecoration: "line-through"}}>{pReward.iPoints}</span><br />New User<br />{iLoginRewardPoints}<br />Reward Points</div></div>
        }

        var pTickets = null;
        if (pReward.iTickets > 0)
        {
            pTickets = <div className="DailyRewardItem"><div className="DailyRaffleImage"></div><div className="DialyRaffleAmount">{pReward.iTickets}<br />Raffle Ticket{pReward.iTickets > 1 ? 's' : ''}</div></div>
        }

        var pCheck = i <= iRewardDay ? <div className="DailyCheck"></div> : null;


        aRewardDays.push (<div key={"Reward" + i} className={"RewardDayContainer" + (i < iRewardDay ? " RewardDayDisabled" : (i === iRewardDay ? ' RewardDaySelected' : ''))}>
            <div className={"DailyItemLabel" + (i === iRewardDay ? " Highlight" : "")}>{i === iRewardDay ? "TODAY" : "DAY " + (i + 1)}</div>
            {pCheck}
            {pPoints}
            {pTickets}
        </div>);
    }
    pRet = <div className="dialog modalBack" style={{minHeight:"32em",maxWidth:"100%"}}>
        <div className="dialogDiv">
            <div className="DailyRewardDialog">
                <div className="DailyRewardImageSmall"></div>
                <div className="headerText" style={{width:"auto"}}>Daily Login Reward</div>
                <div className="DailyConsecutiveDays">(Rewards reset after 7 days of logins.)</div>
                <div style={{display:"flex", width:"100%", justifyContent:"space-evenly", alignItems:"center"}}>
                    <div className="DailyRewardImageContainer">
                        <div className="DailyRewardImage"></div>
                    </div>                    
                    <div className="DailyRewardContainer">{aRewardDays}</div>
                </div>                
            </div>
        </div>                    
        <div className="dialogFrame"></div>
    </div>

    pUserData.iLoginRewardPoints = 0;    // zero out so we don't display again.
    pUserData.iLoginRewardRaffleTickets = 0;   // zero out so we don't display again.

    return pRet;
}

export default DailyReward;
