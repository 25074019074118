import React, { useState, useEffect } from 'react';
import { doPortalRequest } from '../api/MemberPortal.js';
import { useNavigate } from "react-router-dom";
import YesNoPopup from './Popups/YesNoPopup.js';

const pImage = require("../images/UI_maintenance.png");

let tLastMaintenanceMessage = 0;

function CheckMaintenance() {
    // yes no modal dialog
    const [bOpenYesNo, setOpenYesNo] = useState (false);
    const [pMessage, setMessage] = useState (null);
    const [pTitle, setTitle] = useState (null);
    const [sYes] = useState("OKAY");
    const [sNo] = useState(null);
    const [sImgSrc] = useState (pImage);

    const navigate = useNavigate();

    useEffect (() =>
    {
        async function checkMaintenanceMode ()
        {
            let pData = await doPortalRequest (null, "isMaintenance");
            if (pData && pData.iErrorCode === 0)
            {
                if (pData.bMaintenance === 1)
                {
                    navigate ('/maintenance');
                }
                else
                {
                    // if we're on the maintenance page, navigate home.
                    var sPathname = window.location.pathname
                    if (sPathname === '/maintenance')
                    {
                        navigate ('/home');
                    }
                    else
                    {
                        const tNow = Date.now ();
                        if (tNow - tLastMaintenanceMessage > 300000) // 5 minutes
                        {
                            if (pData.sMessage)
                            {
                                tLastMaintenanceMessage = tNow;
                                setMessage (pData.sMessage);
                                setTitle ("Server Maintenance Alert");
                                setOpenYesNo (true);
                            }
                        }
                    }
                }
            }
            else
            {
                navigate ('/maintenance');
            }
        }

        checkMaintenanceMode ();
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, []);
    

    const yesNoCloseHandler = function (bOkay)
    {
        setOpenYesNo(false);
    }


    return (
        <YesNoPopup bOpen={bOpenYesNo} pMessage={pMessage} pTitle={pTitle} sYes={sYes} sNo={sNo} sImgSrc={sImgSrc} onClose={yesNoCloseHandler}></YesNoPopup>
    );
}

export default CheckMaintenance;

