import './App.css';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import { createBrowserHistory } from 'history';
import Modal from "react-modal";
import React, { Suspense, lazy } from 'react';

import HomePage from './pages/HomePage';
import LoadingComponent from './components/LoadingComponent';
import Features from './Features.js';

const InstructionsPage = lazy(() => import('./pages/InstructionsPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const LoginSuccessPage = lazy(() => import('./pages/LoginSuccessPage'));
const RegisterPage = lazy(() => import('./pages/RegisterPage'));
const VerifyPage = lazy(() => import('./pages/VerifyPage'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage'));
const CollectionsPage = lazy(() => import('./pages/CollectionsPage'));
const PlayerInventoryPage = lazy(() => import('./pages/PlayerInventoryPage'));
const WalletRouteOutlet = lazy(() => import('./pages/WalletRouteOutlet'));
const InventoryRouteOutlet = lazy(() => import('./components/Inventory/InventoryRouteOutlet'));
const AppRouteOutlet = lazy(() => import('./pages/AppRouteOutlet'));
const InventoryItemPage = lazy(() => import('./pages/InventoryItemPage'));
const AccountPage = lazy(() => import('./pages/AccountPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const ChangeEmailPage = lazy(() => import('./pages/ChangeEmailPage'));
const RafflePage = lazy(() => import('./pages/RafflePage'));
const GamesPage = lazy(() => import('./pages/GamesPage'));
const ChatPage = lazy(() => import('./pages/ChatPage'));
const RaffleViewerPage = lazy(() => import('./pages/RaffleViewerPage'));
const RewardStorePage = lazy(() => import('./pages/RewardStorePage'));
const RewardStorePageOld = lazy(() => import('./pages/RewardStorePageOld'));
const ViewerPage = lazy(() => import('./pages/ViewerPage'));
const AuthActionPage = lazy(() => import('./pages/AuthActionPage'));
const MaintenancePage = lazy(() => import('./pages/MaintenancePage'));
const ChangeAccountPage = lazy(() => import('./pages/ChangeAccountPage'));
const MonsterBreakerPage = lazy(() => import('./pages/MonsterBreakerPage'));

export const history = createBrowserHistory({
    basename: process.env.PUBLIC_URL
});    

Modal.setAppElement("#root");

  /*
const basename = document.querySelector('base')?.getAttribute('href') ?? '/'    
*/

const App = () => {
    return (
        <div className="App">
            <Router basename={process.env.PUBLIC_URL}>
                <Suspense fallback=<LoadingComponent />>
                    <div>
                        {/* A <Switch> looks through its children <Route>s and renders the first one that matches the current URL. */}
                        <Routes>
                            <Route path="/viewer" element={<ViewerPage />} />
                            <Route path="/authaction" element={<AuthActionPage />} />
                            <Route path="/maintenance" element={<MaintenancePage />} />
                            <Route path="/changeaccount" element={<ChangeAccountPage />} />
                            <Route element={<AppRouteOutlet />}>
                                <Route exact path="/" element={<HomePage />} />
                                <Route path="/instructions" element={<InstructionsPage />} />
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/register" element={<RegisterPage />} />
                                <Route path="/games" element={<GamesPage />} />
                                <Route path="/cursebreaker" element={<MonsterBreakerPage />} />
                                <Route element={<InventoryRouteOutlet />}>
                                    <Route element={<WalletRouteOutlet />}>
                                        <Route path="/home" element={<LoginSuccessPage />} />
                                        <Route path="/account" element={<AccountPage />} />
                                        <Route path="/raffle" element={<RafflePage />} />
                                        <Route path="/raffleviewer" element={<RaffleViewerPage />} />
                                        {Features.bRewardStore ?
                                            <Route path="/rewardstore" element={<RewardStorePage />} />
                                            :
                                            <Route path="/rewardstore" element={<RewardStorePageOld />} />
                                        }
                                        <Route path="/resetpassword" element={<ResetPasswordPage />} />
                                        <Route path="/changeemail" element={<ChangeEmailPage />} />
                                        <Route path="/inventory" element={<CollectionsPage />} />
                                        <Route path="/inventory/collectibles" element={<PlayerInventoryPage />}/>
                                        <Route path="/inventory/item" element={<InventoryItemPage />} />
                                        {Features.bChat &&
                                            <Route path="/chat" element={<ChatPage />} />
                                        }
                                    </Route>
                                </Route>
                                <Route path="/verify" element={<VerifyPage />} />
                                <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
                            </Route>
                        </Routes>
                    </div>
                </Suspense>
            </Router>
        </div>
    );
}


export default App;
