const sQueryString = require('query-string');

const pParams = sQueryString.parse(window.location.search);
console.log ("search:" + window.location.search);

const sDevDir = pParams.dev_dir ? "/dev_dir_" + pParams.dev_dir : "/dev_dir_albert"
//const bDev = (process.env.REACT_APP_API === 'dev' || process.env.REACT_APP_API === 'staging');
//const bDebug = ((pParams.debug === '1' || pParams.debug === 'true' ) && bDev == false);

const WEB_PORTAL_API_ADDRESS_DEV = "https://restdev.cosmicfoundry.io" + sDevDir + "/cthulhu/rest/webportal/";
const WEB_PORTAL_API_ADDRESS_STAGING = "https://restdev.cosmicfoundry.io/cthulhu/rest/webportal/";
const WEB_PORTAL_API_ADDRESS_PROD = "https://rest.cosmicfoundry.io/cthulhu/rest/webportal/";

const WHITELIST_API_ADDRESS_DEV = "https://restdev.cosmicfoundry.io" + sDevDir + "/cthulhu/rest/whitelist/";
const WHITELIST_API_ADDRESS_STAGING = "https://restdev.cosmicfoundry.io/cthulhu/rest/whitelist/";
const WHITELIST_API_ADDRESS_PROD = "https://rest.cosmicfoundry.io/cthulhu/rest/whitelist/";

const CURSEBREAKER_API_ADDRESS_DEV = "https://restdev.cosmicfoundry.io" + sDevDir + "/cthulhu/rest/monsterbreaker/";
const CURSEBREAKER_API_ADDRESS_STAGING = "https://restdev.cosmicfoundry.io/cthulhu/rest/monsterbreaker/";
const CURSEBREAKER_API_ADDRESS_PROD = "https://rest.cosmicfoundry.io/cthulhu/rest/monsterbreaker/";

const CURSEBREAKER_API_ADDRESS = process.env.REACT_APP_API === 'dev' ? CURSEBREAKER_API_ADDRESS_DEV : (process.env.REACT_APP_API === 'staging' ? CURSEBREAKER_API_ADDRESS_STAGING : CURSEBREAKER_API_ADDRESS_PROD);
const WHITELIST_API_ADDRESS = process.env.REACT_APP_API === 'dev' ? WHITELIST_API_ADDRESS_DEV : (process.env.REACT_APP_API === 'staging' ? WHITELIST_API_ADDRESS_STAGING : WHITELIST_API_ADDRESS_PROD);
const WEB_PORTAL_API_ADDRESS = process.env.REACT_APP_API === 'dev' ? WEB_PORTAL_API_ADDRESS_DEV : (process.env.REACT_APP_API === 'staging'? WEB_PORTAL_API_ADDRESS_STAGING : WEB_PORTAL_API_ADDRESS_PROD);

console.log ("API URL" + WEB_PORTAL_API_ADDRESS);

let sLastKnownVersion = null;

// endpoints don't need beginning /
async function doPortalRequest (sFirebaseUserIDToken, sEndpoint, sBody)
{
    return await _doAuthorizedRequest (WEB_PORTAL_API_ADDRESS, sFirebaseUserIDToken, sEndpoint, sBody);
}

// endpoints don't need beginning /
async function doCurseBreakerRequest (sFirebaseUserIDToken, sEndpoint, sBody)
{
    return await _doAuthorizedRequest (CURSEBREAKER_API_ADDRESS, sFirebaseUserIDToken, sEndpoint, sBody);
}

// endpoints don't need beginning /
async function _doAuthorizedRequest (sRestAPIAddress, sFirebaseUserIDToken, sEndpoint, sBody)
{
    if (sBody === undefined)
        sBody = "";

    const isString = (typeof sBody === 'string' || sBody instanceof String); 
    const pBodyData = new URLSearchParams();
    if (isString)
    {
        var aBody = sBody.split ("&");
        for (var i = 0; i < aBody.length; i++)
        {
            var aParts = aBody[i].split('=');
            pBodyData.append (aParts[0], aParts.length > 1 ? aParts[1] : "");
        }
    }
    else
    {
        for (const sProperty in sBody) 
        {
            pBodyData.append (sProperty, sBody[sProperty]);
        }        
    }

    // okay, now that we know we're logged in, go ahead and query server for collections/inventory
    // send to server the address to associate with the ID.
    const pRequestOptions = sFirebaseUserIDToken ? 
    {
        method: 'POST',
        //mode: 'no-cors',    // not be used in production
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': "Bearer " + (sFirebaseUserIDToken ? sFirebaseUserIDToken : ""),
            "Access-Control-Request-Headers" : "Client-Version"
        }),
        body: pBodyData
    } :
    {
        method: 'POST',
        //mode: 'no-cors',    // not be used in production
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
            "Access-Control-Request-Headers" : "Client-Version"
        }),
        body: pBodyData
    }

    try
    {
        const pResponse = await fetch(sRestAPIAddress + sEndpoint, pRequestOptions);
        if (pResponse.status === 200)
        {
            // store off the version number from the server. If version number changes, we refresh the browser to make sure all react code is reloaded.
            var sVersion = pResponse.headers.get ('Client-Version'); 
            if (sVersion)
            {
                // if a client version was returned, then we do the checking, otherwise we skip it.
                if (sLastKnownVersion === null)
                    sLastKnownVersion = sVersion;   // we've never seen a version number before, store it off so we can check if it's changed in a future request.
                else if (sLastKnownVersion != sVersion)
                {
                    // version # has changed, reload the page to get react app to reload.
                    window.location.reload ();
                    return;
                }
            }
                
            const isJson = pResponse.headers.get('content-type')?.includes('application/json');
            let pData;
            if (isJson) {
                pData = await pResponse.json();
            }
            else 
            {
                const isAudio = pResponse.headers.get('content-type')?.includes('audio/');
                if (isAudio)
                {
                    pData = await _getAudioBuffer (pResponse.body);
                }
                else
                {
                    const sText = await pResponse.text();
                    try
                    {
                        pData = JSON.parse(sText);
                    }
                    catch (e)
                    {
                        pData = sText;  // response is not json, just return text.
                    }
                }
            }
            return pData;
        }
        else   
        {
            return {iErrorCode:pResponse.status};
        }
    }
    catch (err)
    {
        return null;
    }
}

async function _getAudioBuffer (pResponse)
{
    // helper function to convert stream to audio buffer
    const pReader = pResponse.getReader();
    const aChunks = [];

    while (true) 
    {
        const { done, value } = await pReader.read();
        if (done) break;

        aChunks.push(value);
    }

    const dataArray = aChunks.reduce(
        (acc, chunk) => Uint8Array.from([...acc, ...chunk]),
        new Uint8Array(0)
    );

    return Buffer.from(dataArray.buffer);
}


// endpoints don't need beginning /
async function doWhitelistRequest (sEndpoint, sBody)
{
    if (sBody === undefined)
        sBody = "";

    const isString = (typeof sBody === 'string' || sBody instanceof String); 
    const pBodyData = new URLSearchParams();
    if (isString)
    {
        var aBody = sBody.split ("&");
        for (var i = 0; i < aBody.length; i++)
        {
            var aParts = aBody[i].split('=');
            pBodyData.append (aParts[0], aParts.length > 1 ? aParts[1] : "");
        }
    }
    else
    {
        for (const sProperty in sBody) 
        {
            pBodyData.append (sProperty, sBody[sProperty]);
        }        
    }

    // okay, now that we know we're logged in, go ahead and query server for collections/inventory
    // send to server the address to associate with the ID.
    const pRequestOptions = 
    {
        method: 'POST',
        //mode: 'no-cors',    // not be used in production
        mode: 'cors',
        headers: new Headers({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
        body: pBodyData
    };

    try
    {
        const pResponse = await fetch(WHITELIST_API_ADDRESS + sEndpoint, pRequestOptions);
        if (pResponse.status === 200)
        {
            const isJson = pResponse.headers.get('content-type')?.includes('application/json');
            let pData;
            if (isJson) {
                pData = await pResponse.json();
            }
            else {
                const sText = await pResponse.text();
                try
                {
                    pData = JSON.parse(sText);
                }
                catch (e)
                {
                    pData = sText;  // response is not json, just return text.
                }
            }
            return pData;
        }
        else
            return null;
    }
    catch (err)
    {
        return null;
    }
}

export { doPortalRequest, doWhitelistRequest, doCurseBreakerRequest};