import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

/*
const linkRenderer = (string) => {
    const linkExp = /^https?:\/\/[a-z0-9_.\/-]*$/i
    return <>{
        string.split(/(https?:\/\/[a-z0-9_.\/-]*?)([.,]?)$/gi).map((part, k) => <React.Fragment key={k}>
            {part.match(linkExp) ? <a
                href={part}
                onFocus={(e) => { e.stopPropagation() }}
                target="_blank"
                rel="noreferrer"
            >{part}</a>
            : part}
        </React.Fragment>)
    }</>
}
*/

function SimpleText(pProps) 
{
    const sText = pProps.text;
    if (sText && sText.length > 0)
    {
        /*
        const pDescription = linkRenderer (sText);
        */
        return (<div {...pProps} ><ReactMarkdown linkTarget="_blank" linkReferrer="noreferrer" children={sText} remarkPlugins={[remarkGfm]} 
        components={{
            // Rewrite `em`s (`*like so*`) to `i` with a red foreground color.
            strong: ({node, ...props}) => <strong style={{color: 'var(--markdown-bold-color)'}} {...props} />
          }}
        /></div>);
        
    }        
    else
        return null;

}
  
export default SimpleText;
  