import React from 'react';
import NFTViewer from '../NFTViewer.js';

function CthulhuAgentNFTPopup(pUserData) 
{
    let pRet = null;
    if (pUserData.pNFT)
    {
        pRet = <div id="NFTPopup" className="dialog modalBack" style={{minWidth:"60vw"}}>
            <div className="dialogDiv">
                <div className="NFTFreeContainer">
                    <p className="NFTFreeHeader">You received a free CthulheVerse Digital Collectible: Agent for beating the M1 Demo!</p>
                    <NFTViewer pNFT={pUserData.pNFT} />
                </div>                    
            </div>                    
            <div className="dialogFrame"></div>
        </div>
        pUserData.pNFT = null;  // null out so we don't display again.
    }
    return pRet;
}

export default CthulhuAgentNFTPopup;
