import React, { useState, useEffect } from 'react';
import { AppContext, LOGIN_STATES } from '../../contexts/AppContext.js';
import Modal from "react-modal";
import AgentNFTPopup from './AgentNFTPopup.js';
import CthulhuAgentNFTPopup from './CthulhuAgentNFTPopup.js';
import ArtNFTPopup from './ArtNFTPopup.js';
import RegistrationRaffleTickets from './RegistrationRaffleTickets.js';
import DailyReward from './DailyReward.js';
import ReferralNoGood from './ReferralNoGood.js';

function ModalPopups() {
    const pAppData = React.useContext(AppContext);
    
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalList, setModalList] = useState ([]);

    const pUserData = pAppData.pUserData;

    useEffect (() =>
    {
        var aList = [];
        if (pAppData.iLoginState === LOGIN_STATES.LOGIN_SUCCESS)
        {
            let pPopup;

            pPopup = CthulhuAgentNFTPopup (pUserData);
            if (pPopup != null)
                aList.push (pPopup);

            pPopup = AgentNFTPopup (pUserData);
            if (pPopup != null)
                aList.push (pPopup);

            pPopup = ArtNFTPopup (pUserData);
            if (pPopup != null)
                aList.push (pPopup);

            pPopup = RegistrationRaffleTickets (pUserData);
            if (pPopup)
                aList.push (pPopup);

            pPopup = ReferralNoGood (pUserData);
            if (pPopup)
                aList.push (pPopup);

            pPopup = DailyReward (pUserData, pAppData.pGameVars);
            if (pPopup)
                aList.push (pPopup);
    
            pAppData.updateAppData ("pUserData", pUserData);
            setModalList (modalList.concat (aList));
        }

        if (modalList.length > 0 || aList.length > 0)
        {
            openModal ();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps        
    }, [pAppData.iLoginState]);

    function closeModal ()
    {
        setModalOpen (false);
    }

    function openModal ()
    {
        setModalOpen (true);
    }
    function nextModal ()
    {
        // if we just closed modal dialog, then remove the displayed modal content
        // by shifting the array.
        var aList = modalList;
        aList.shift ();
        setModalList (aList);
        if (aList.length > 0)
            openModal ();
    }

    if (!pUserData)
    {
        return null;
    }

    return (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="CthulhuVerse"
            className="ModalDialog"
            overlayClassName="ModalDialogOverlay"
            closeTimeoutMS={600} 
            shouldCloseOnOverlayClick={true}
            onAfterClose={nextModal}
        >
            {modalList[0]}
            <div style={{display:"flex", justifyContent:"center"}}>
                <button className="BackButton" onClick={closeModal}>
                    <span className="ButtonText">CLOSE</span>
                </button>                
            </div>
        </Modal>
    );
}

export default ModalPopups;
